import React from "react";
import { MenuItem } from "../../../Menu/components/MenuItem";

import styles from "./styles.scss";

export const FooterMenu = () => {
  const menuItems: [string, string][] = [
    ["/about_me", "About Me"],
    ["/projects", "Projects"],
    ["/technologies", "Technologies"],
    ["/resume", "Résumé"],
    ["/contact", "Contact"],
  ];

  return (
    <div className={styles.FooterMenu || "FooterMenu"}>
      {menuItems.flatMap(([url, contents], i) => [
        i > 0 ? "|" : null,
        <MenuItem url={url} contents={contents} key={`footer-menu-${i}`} />,
      ])}
    </div>
  );
};
