import React from "react";
import { MenuItem, MenuItemVariation } from "./components/MenuItem";

import style from "./style.scss";

interface Props {
  home?: boolean;
}

export const Menu = ({ home }: Props) => (
  <div className={style.Menu || "Menu"}>
    {home && <MenuItem url="/" contents="Home" />}
    <MenuItem url="/about_me" contents="About Me" />
    <MenuItem url="/projects" contents="Projects" />
    <MenuItem url="/technologies" contents="Technologies" />
    <MenuItem url="/resume" contents="Résumé" />
    <MenuItem
      url="/contact"
      contents="Contact"
      variation={MenuItemVariation.Underlined}
    />
  </div>
);
