import React from "react";
import { Link } from "react-router-dom";

import style from "./style.scss";

interface Props {
  url: string;
  contents: string;
  variation?: MenuItemVariation;
}

export enum MenuItemVariation {
  Underlined,
}

export const MenuItem = ({ url, contents, variation }: Props) => {
  const underlinedStyle =
    variation === MenuItemVariation.Underlined
      ? style.Underlined || "Underlined"
      : "";

  return (
    <Link
      className={`${style.MenuItem || "MenuItem"} ${underlinedStyle}`}
      to={url}
    >
      {contents}
    </Link>
  );
};
