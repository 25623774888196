import React from "react";

import { Menu } from "../Menu";
import { SiteLogo } from "../SiteLogo";
import styles from "./styles.scss";

interface Props {
  home?: boolean;
}

export const Header = ({ home }: Props) => {
  return (
    <div className={styles.Header || "Header"}>
      <SiteLogo home={home} />
      <Menu home={home} />
    </div>
  );
};
