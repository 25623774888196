import React from "react";

import styles from "./styles.scss";
import { FooterLogo, FooterMenu, Legal } from "./components";

export const Footer = () => (
  <div className={styles.Footer || "Footer"}>
    <FooterLogo />
    <>Michael Margel</>
    <FooterMenu />
    <Legal />
  </div>
);
