import footerLogo from "./images/personal_logo_inverted.svg";

import styles from "./styles.scss";

export const FooterLogo = () => (
  <img
    src={footerLogo}
    className={styles.FooterLogo || "FooterLogo"}
    alt="inverted logo"
  />
);
