import { MenuItem } from "../../../Menu/components/MenuItem";

import styles from "./styles.scss";

export const Legal = () => {
  const legalLinks = [
    <MenuItem url={"legal/tos"} contents={"Terms of Service"} />,
    " | ",
    <MenuItem url={"legal/privacy"} contents={"Privacy Policy"} />,
  ];
  return (
    <div className={styles.Legal || "Legal"}>
      <div>{legalLinks}</div>
      <div>Copyright © 2016 Michael Margel. All rights reserved</div>
    </div>
  );
};
