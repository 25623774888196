import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import { Router } from "../Router";
import style from "./style.scss";

const client = new ApolloClient({
  uri: "/graphql",
  cache: new InMemoryCache(),
});

export const App = () => {
  return (
    <ApolloProvider client={client}>
      <div className={style.Global || "Global"}>
        <Router />
      </div>
    </ApolloProvider>
  );
};
