import React from "react";

import styles from "./styles.scss";

interface Props {
  home?: boolean;
}

export const SiteLogo = ({ home }: Props) => {
  if (home) {
    return (
      <span className={styles.TextLogo || "TextLogo"}>Michael Margel</span>
    );
  } else {
    return <>{"<placeholder img>"}</>;
  }
};
