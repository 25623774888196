import React from "react";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { BackgroundImage } from "../../foundation/BackgroundImage";

import background from "./images/background.png";

export const Home = () => {
  return (
    <>
      <BackgroundImage src={background} />
      <Header home={true} />
      {/* <BioPreview />
    <ProjectPreview />
    <TechnologyPreview />
  */}
      <Footer />
    </>
  );
};
