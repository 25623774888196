import React from "react";

import styles from "./styles.scss";

interface Props {
  src: string;
}

export const BackgroundImage = ({ src }: Props) => (
  <div className={styles.BackgroundWrapper || "BackgroundWrapper"}>
    <img src={src} />
  </div>
);
