import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "./foundation";

const container = document.getElementById("app");
console.log("container: ", container);
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(<App />);
